var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2 px-2"},[_c('showAt',{attrs:{"breakpoint":"small"}},[_c('div',{staticClass:"btn_prev_container"},[_c('div',{staticClass:"btn_prev_stage_mobile",staticStyle:{"margin-top":"20px"}},[_c('b-link',{on:{"click":_vm.goBack}},[_c('feather-icon',{staticStyle:{"color":"#9CA2B1","transform":"rotate(-90deg)"},attrs:{"icon":"ChevronUpIcon","size":"24"}})],1)],1)])]),_c('div',{staticClass:"auth-inner py-2"},[_c('b-overlay',{attrs:{"show":_vm.is_loading,"spinner-variant":"primary","variant":"transparent","rounded":"sm"}},[(_vm.is_twofactor_required)?_c('b-card',{staticClass:"mb-0",staticStyle:{"border-radius":"24px"}},[_c('center',[_c('showAt',{attrs:{"breakpoint":"mediumAndAbove"}},[_c('div',{staticClass:"btn_prev_stage_desktop",staticStyle:{"margin-top":"20px"}},[_c('b-link',{on:{"click":_vm.goBack}},[_c('feather-icon',{staticStyle:{"color":"#9CA2B1","transform":"rotate(-90deg)"},attrs:{"icon":"ChevronUpIcon","size":"24"}})],1)],1)]),(_vm.skin.value === 'light' )?_c('b-img',{attrs:{"src":require("@/assets/images/logo/logo_title_flat.png"),"fluid":"","width":"110px"}}):_c('b-img',{attrs:{"src":require("@/assets/images/logo/logo_title_flat_white.png"),"fluid":"","width":"110px"}})],1),_c('b-card-title',{staticClass:"text-center",staticStyle:{"margin-top":"32px","margin-bottom":"6px"}},[_vm._v(" "+_vm._s(_vm.$t('auth.two-auth'))+" ")]),_c('b-card-text',{staticClass:"mb-2 text-center text-muted",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t('auth.two-msg'))+_vm._s(_vm.userEmail)+". ")]),_c('validation-observer',{ref:"twoFactor",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"two-factor-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.completeTwoFactor($event)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"«Код подтвеждения»","rules":"required|digits:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"auth-code","name":"auth-code","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('auth.placeholders.code'),"autofocus":""},model:{value:(_vm.c),callback:function ($$v) {_vm.c=_vm._n($$v)},expression:"c"}})]}}],null,true)})],1),_c('b-button',{staticClass:"submit-auth-v2",attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('auth.btn'))+" ")])],1),(_vm.error)?_c('b-card-text',{staticClass:"text-danger text-center mt-2",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.error))]):_vm._e(),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.wrong-email')))]),_vm._v(" "),_c('br'),_c('b-link',{on:{"click":function($event){_vm.is_twofactor_required = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.specify-correct')))])])],1)]}}],null,false,2244793285)})],1):_c('b-card',{staticClass:"mb-0",staticStyle:{"border-radius":"24px"}},[_c('center',[_c('showAt',{attrs:{"breakpoint":"mediumAndAbove"}},[_c('div',{staticClass:"btn_prev_stage_desktop",staticStyle:{"margin-top":"20px"}},[_c('b-link',{on:{"click":_vm.goBack}},[_c('feather-icon',{staticStyle:{"color":"#9CA2B1","transform":"rotate(-90deg)"},attrs:{"icon":"ChevronUpIcon","size":"24"}})],1)],1)])],1),_c('b-card-title',{staticClass:"text-center",staticStyle:{"margin-top":"32px","margin-bottom":"4px","font-size":"20px"}}),_c('b-card-text',{staticClass:"mb-2 text-center text-muted",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('auth.subtitle'))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"«Электронная почта»","rules":"required|email","localize":"ru"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('auth.placeholders.email'),"autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-link',{attrs:{"to":{ name:'auth-password-forgot' }}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('auth.fogot')))])])],1),_c('validation-provider',{attrs:{"name":"«Пароль»","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"login-password","placeholder":_vm.$t('auth.placeholders.password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.keepMeLoginIn),callback:function ($$v) {_vm.keepMeLoginIn=$$v},expression:"keepMeLoginIn"}},[_vm._v(" "+_vm._s(_vm.$t('auth.remember-me'))+" ")])],1),_c('b-button',{staticClass:"submit-auth-v2",attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('auth.btn'))+" ")])],1)]}}])}),(_vm.error)?_c('b-card-text',{staticClass:"text-danger text-center mt-2",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.error))]):_vm._e(),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.no-account-yet')))]),_c('b-link',{on:{"click":function($event){_vm.modalsVisibility.callback = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.btn-register')))])])],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"connect-modal","title":"Для подключения необходимо подтверждение квалификации веб-мастера","centered":"","hide-footer":""},model:{value:(_vm.modalsVisibility.callback),callback:function ($$v) {_vm.$set(_vm.modalsVisibility, "callback", $$v)},expression:"modalsVisibility.callback"}},[_c('callback-modal-content')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }