


    import $request from "@/modules/request/"
    import $utils from "@/modules/utils/"
    
    
    export default {

        is_can_show: false,

        charts: [ 
            { endpoint: 'orders', name: "", label: 'Заявки' },
            { endpoint: 'clicks', name: "", label: 'Клики' },
            // { endpoint: 'difficulty', name: "", label: ''  },
            // { endpoint: 'miners-revenue', name: "", label: '', series: [] },
        ],
        
        filters: {
            'orders' : {
                period: 'today'
            },
            'clicks' : {
                period: 'today'
            },
            // 'difficulty' : {
            //     period: '3months'
            // },
            // 'miners-revenue' : {
            //     period: '3months'
            // }
        },
        
        update( $name ) {
            return new Promise((resolve, reject) => {
                $request.get(`chart.${$name}`, this.filters[$name] ).then(result => {
                   
                    this[$name] = {
                        label: "",
                        series: [],
                        labels: []
                    };

                    let chartData =  {
                        name: "",
                        data: []
                    };

                    result.forEach(item => {
                         chartData.data.push(item.y);
                         this[$name].labels.push(item.x);
                     });

                    this[$name].series = [chartData];
                    
                    
                    resolve( result );
                    

                   });
            });
        },
        
        getMarketPricePercent() {
            
            if( !this.hasOwnProperty('market-price') ) {
                return 0;
            }
            
            let data = this['market-price'].series[0].data;
                        
            let firstValue = data[0];
            let lastValue = data[ data.length - 1 ];
            
            return ((( lastValue - firstValue ) / firstValue) * 100);
            
        },
        
        get() {
            
            this.charts.forEach( (chart, index) => {
               $request.get(`chart.${chart.endpoint}`, this.filters[chart.endpoint] ).then(result => {
                   
                     this[chart.endpoint] = {
                         label: chart.label,
                         series: [],
                         labels: []
                     };
                     
                     let chartData =  {
                         name: chart.name,
                         data: [],
                     };
                     
                     result.forEach(item => {
                         chartData.data.push(item.y);
                         this[chart.endpoint].labels.push(item.x);
                     });
                    
                    this[chart.endpoint].series = [chartData];

                    if( index === this.charts.length - 1 ) {
                        this.is_can_show = true;
                    }

                    
               });
               
            });
        },
        
    }